import React from 'react';
import styles from '../styles/Tokens.module.css'



const Tokens = ({ tokenData }) => {
    return (
        <div>
            <h1>collection</h1>
            <div className={styles.gallery}>
                {tokenData.map((token, index) => (
                    <div key={index} className={styles.token}>
                        <img src={token.image} alt={token.name} className={styles.image} />
                        <p className={styles.tokenTitle}>{token.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tokens;
