import React, { useState } from 'react';
import styles from '../styles/BlogEntry.module.css'; 


const BlogEntry = ({ title, date, link, description }) => {

    return (
      <div className={styles.entry}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <a href={link} target="_self" rel="noopener noreferrer" className={styles.readMore}>
            read
        </a>
      </div>
      
    );
  };
  
  export default BlogEntry;