import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXTwitter, faGithub, faDiscord, faLinkedin, faTelegram } from '@fortawesome/free-brands-svg-icons'
import styles from './styles/Profile.module.css';


const Profile = ({ imageUrl, name, twitter, github, discord, telegram, linkedin }) => {

    const url = "https://app.ens.domains/adebimpe.xyz";
    return (
        <div className={styles.profileContainer}>
                <img src={imageUrl} alt="Profile" className={styles.profilePic}/>
                <h2 className={styles.name}>{name}</h2>
                <h3 className={styles.ens}>
                    <a href={url}>adebimpe.xyz</a>
                </h3>
            <div>
                <div className={styles.socialLinks}>
                    <a href={twitter} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSquareXTwitter} className={styles.xIcon}/>
                    </a>
                    <a href={github} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub} className={styles.githubIcon}/>
                    </a>
                    <a href={discord} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDiscord} className={styles.discordIcon}/>
                    </a>
                    <a href={telegram} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTelegram} className={styles.telegramIcon}/>
                    </a>
                    <a href={linkedin} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} className={styles.linkedinIcon}/>
                    </a>
            </div>

            </div>
        </div>
    )
}


export default Profile;