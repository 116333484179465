import React, { useState, useEffect } from 'react';
import { Network, Alchemy } from "alchemy-sdk";
import Tokens from './Tokens';
import CustomDropdown from './CustomDropdown';

export const getNFTsOnEthereum = async () => {
    const settings = {
        apiKey: "0x3z9Qf67uu98PsIM3FDCCNQdBJ2YEKw", 
        network: Network.ETH_MAINNET, 
      };
      const ethereum = new Alchemy(settings);

      const nftsOnEthereum = await ethereum.nft.getNftsForOwner("adebimpe.xyz");

      const filteredNFTs = nftsOnEthereum.ownedNfts.map((nft) => ({
        tokenId: nft.tokenId,
        name: nft.name,
        image: nft?.image?.originalUrl,
        description: nft?.description,
        tokenType: nft.tokenType
      }))

      return filteredNFTs;
}

export const getNFTsOnBase= async () => {
    const settings = {
        apiKey: "0x3z9Qf67uu98PsIM3FDCCNQdBJ2YEKw", 
        network: Network.BASE_MAINNET, 
      };
      const base = new Alchemy(settings);

      const nftsOnBase= await base.nft.getNftsForOwner('0x88e4519e2Baa513Ed92B0Ae4c788D7E5c5B03Ea4');

      const filteredNFTs = nftsOnBase.ownedNfts.map((nft) => ({
        tokenId: nft.tokenId,
        name: nft.name,
        image: nft?.image?.originalUrl,
        description: nft?.description,
        tokenType: nft.tokenType
      }))

      return filteredNFTs;
}


const NFTGallery = () => {
    const [tokenData, setTokenData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [network, setNetwork] = useState('base'); 

    useEffect(() => {
        async function fetchNFTs() {
            setLoading(true);
            setError(null);
            try {
                let nfts = [];
                if (network === 'base') {
                    nfts = await getNFTsOnBase();
                } else if (network === 'ethereum') {
                    nfts = await getNFTsOnEthereum();
                }
                setTokenData(nfts);
            } catch (err) {
                setError('Failed to fetch NFTs');
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        fetchNFTs();
    }, [network]);

    if (loading) return <p>loading nfts...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>

            <CustomDropdown value={network} onChange={setNetwork}/>
            <Tokens tokenData={tokenData} />
        </div>
    );
};

export default NFTGallery;
