import React from 'react';
import styles from '../styles/Art.module.css'

const Art = ({ artPieces }) => {
    return (
        <div className={styles.artContainer}>
            <h1 className={styles.title}>lens</h1>
            {artPieces.map((piece, index) => (
                <div key={index} className={styles.artEntry}>
                    <img src={piece.src} alt={piece.title} className={styles.artImage}/>
                    <p className={styles.artDescription}>{piece.description}</p>
                </div> 
            ))}
        </div>
    )
}

export default Art;