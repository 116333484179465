import React from 'react';
import styles from '../styles/AboutMe.module.css';

const AboutMe = () => {
    return (
        <div className={styles.aboutMeContainer}>
            <div className={styles.content}>
                <h1 class>about</h1>
                <p>
                    I'm 24, going on 25, living in Bushwick, Brooklyn.

                <p>I work at EY on the Digital Asset Research Team, as an Engineer and Consultant.</p> 
                </p>
                <p>
                    I love web3, its just like New York - a public place where people from all walks of life can 
                    build establishments of their own expression. A platform of culture that's always bustling. 
                </p>
                <p>
                    I have two personas, one tradfi, and one degen (or regen, rather, since I'm interested in more regenerative web3 use cases).
                </p>
            <p>
                In the tradfi world, i'm interested in helping traditional financial institutions tokenize assets and financial products. 
                With the help of my colleagues, i've put together a New York Digital Asset Learning Series that aims to educate TradFi players
                on the basics. 
            </p>
            <p>
                In web3, i'm very interested in helping people create onchain identities that can be used to establish provenance online.
                I'm also inspired by communities that are organized in a grassroots style, who's members are motivated to work toward a common goal. 
            </p>
            <p>
                I enjoy meeting others in the space, sharing stories and discussing ideas. Feel free to ping me on telegram (linked here on the left).
                I'd love to connect with you. 
            </p>
            </div>
        </div>
    )
};

export default AboutMe;