import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.data.icon && <img src={props.data.icon} alt="" style={{ width: 20, height: 20, marginRight: 10 }} />}
                {props.data.label}
            </div>
        </components.Option>
    );
};

const networkOptions = [
    {
        value: 'base',
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src="/base-logo.png" alt="Base" style={{ width: 20, height: 20, marginRight: 10 }} />
                Base Network
            </div>
        ),
    },
    {
        value: 'ethereum',
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src="/ethereum-logo.png" alt="Ethereum" style={{ width: 15, height: 20, marginRight: 10 }} />
                Ethereum Network
            </div>
        ),
    }
];

const CustomDropdown = ({ value, onChange }) => {
    return (
        <Select
            options={networkOptions}
            value={networkOptions.find(option => option.value === value)}
            onChange={option => onChange(option.value)}
            components={{ Option: CustomOption }}
            styles={{
                control: (provided) => ({
                    ...provided,
                    width: 300,
                    borderRadius: 4,
                    borderColor: '#ddd',
                    boxShadow: 'none',
                    '&:hover': {
                        borderColor: '#aaa'
                    },
                    fontFamily: 'Satoshi'
                })
            }}
        />
    );
};

export default CustomDropdown;
