import React from 'react';
import BlogEntry from './BlogEntry';
import styles from '../styles/Writing.module.css';

const Writing = ({ entries }) => {
  return (
    <div className={styles.container}>
        <h1 className={styles.title}>thoughts & experiences</h1>
      {entries.map((entry, index) => (
        <BlogEntry key={index} {...entry} />
      ))}
    </div>
  );
};

export default Writing;
